@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Inter:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;900&family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './custom-scrollbar.css';

/* 04 Jul 2024 - Added styles for ordered and unordered lists */
ul {
  @apply list-disc pl-5; /* Add disc bullets and padding to unordered lists */
}

ol {
  @apply list-decimal pl-5; /* Add decimal numbers and padding to ordered lists */
}

li {
  margin-bottom: 0.5rem; /* Add some spacing between list items */
  margin-left: 1rem;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 210 40% 98%;
 
    /* --ring: 215 20.2% 65.1%; */
 
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.slate-h2 {
  /* Add your desired styles for h2 elements */
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.67em 0;
}

.custom-quill-editor {
  height: 128px; /* Adjust based on your requirement */
  overflow-y: auto; /* Enable vertical scrolling */
  }

  .custom-quill-editor2 {
    height: fit-content;
    /* min-height: fit-content; 
    max-height: fit-content; */
    /* overflow-y: auto; Enable vertical scrolling */
  }

  .custom-quill-editor .ql-container {
    border: none !important;
  }
  
  .custom-quill-editor .ql-toolbar {
    border: 1px; 
    border-radius: 0.25rem;
  }

.limit-height {
    height: calc(2 * (1rem + 1rem + 0.25rem * 2)); /* Adjust based on the actual height of two rows */
    overflow: hidden;
}

.content-container ol, .content-container ul {
  padding-left: 20px; /* Adjust this value to increase or decrease indentation */
  padding-bottom: 5px;
  list-style-position: inside; /* Adjusts the position of the list marker */
  list-style-type: decimal; /* Ensures ordered lists have numbers */
}

.content-container ul {

  list-style-type: disc; /* Ensures unordered lists have bullets */
}
  
.content-container li {
  margin-left: 20px; /* Adjust as needed for further indentation */
}

/* React Quill Custom Styles */
.ql-container {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 1.6;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.custom-quill-editor .ql-editor {
  height: 200px;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.custom-quill-editor2 .ql-editor {
  min-height: 100px;
  max-height: fit-content;
  padding: 10px;
  border-radius: 6px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  background-color: #f9f9f9;
}

.custom-quill-editor .ql-toolbar .ql-picker-label,
.custom-quill-editor2 .ql-toolbar .ql-picker-label {
  font-weight: normal;
  color: #333;
}

.custom-quill-editor .ql-toolbar .ql-picker-options,
.custom-quill-editor2 .ql-toolbar .ql-picker-options {
  background-color: #fff;
  border: 1px solid #ccc;
}

.ql-snow .ql-stroke {
  stroke: #333;
}

.custom-quill-editor .ql-toolbar.ql-snow,
.custom-quill-editor2 .ql-toolbar.ql-snow {
  background-color: #e9e9e9;
}

.custom-quill-editor3 {
  height: 128px; /* Adjust based on your requirement */
  overflow-y: auto; /* Enable vertical scrolling */
  }

  

.custom-quill-editor3 .ql-container {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 1.8;
  border-radius: 4px;
  border: none !important;
  /* border: 2px solid #ddd; */
}

.custom-quill-editor3 .ql-editor {
  min-height: 150px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fefefe;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.custom-quill-editor3 .ql-toolbar.ql-snow {
  border: 2px solid #ddd;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  background-color: #f0f0f0;
}

.custom-quill-editor3 .ql-toolbar .ql-picker-label {
  font-weight: normal;
  color: #000;
}

.custom-quill-editor3 .ql-toolbar .ql-picker-options {
  background-color: #fefefe;
  border: 4px solid #ddd;
}

.custom-quill-editor3 .ql-snow .ql-stroke {
  stroke: #444;
}

/* Settings for Radio buttons 01 Jul 2024*/
/* Include this in a CSS file or in your global styles */
input[type='radio'] {
  position: relative;
  width: 16px;
  height: 16px;
}

input[type='radio']::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 50%;
}

/* Outer border - checked state */
input[type='radio']:checked::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fed7aa;
  border: 1px solid #ea580c; /* Change this to your desired border color */
  border-radius: 50%;
}

input[type='radio']:checked::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: #ea580c; /* Change this to your desired color */
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
}